import {Option} from '../../ui/interfaces/Option'
import {isNullOrEmpty} from '../utils'

enum EthnicIdentityEnum {
  SpeakFluentFrenchEnglish = 'Speak fluent French as well as English.',
  FemaleOperated = 'We are female-owned and operated.',
  IdentifyAsLGBTQ2SPlus = 'Identify as LGBTQ2S+.',
  IdentifyAsRegisteredVeteran = 'Me, or someone in my group is a registered veteran.',
  UnderAgeOfNineteen = 'Me, or someone in my group is under the age of 19.',
  IdentifyAsIndigenousMetisFirstNations = 'Identify as Indigenous, Metis, or First Nations.',
  IdentifyAsBlackHispanicLatino = 'Identify as Black, Hispanic, POC.',
}

function printEthnicIdentity(value: any): string {
  switch (value) {
    case EthnicIdentityEnum.SpeakFluentFrenchEnglish:
      return 'Speak fluent French as well as English.'
    case EthnicIdentityEnum.FemaleOperated:
      return 'We are female-owned and operated.'
    case EthnicIdentityEnum.IdentifyAsLGBTQ2SPlus:
      return 'Identify as LGBTQ2S+.'
    case EthnicIdentityEnum.IdentifyAsRegisteredVeteran:
      return 'Me, or someone in my group is a registered veteran.'
    case EthnicIdentityEnum.UnderAgeOfNineteen:
      return 'Me, or someone in my group is under the age of 19.'
    case EthnicIdentityEnum.IdentifyAsIndigenousMetisFirstNations:
      return 'Identify as Indigenous, Metis, or First Nations.'
    case EthnicIdentityEnum.IdentifyAsBlackHispanicLatino:
      return 'Identify as Black, Hispanic, POC.'
    default:
      return ''
  }
}

function getSelectedEthnicIdentityEnumOptions(enumString: string): Option[] {
  const keys = enumString.split(',').map((key) => key.trim()) // Split and trim keys
  const result = keys
    .filter((key) => key in EthnicIdentityEnum) // Filter valid enum keys
    .map((key) => ({
      label: printEthnicIdentity(EthnicIdentityEnum[key as keyof typeof EthnicIdentityEnum]),
      value: key,
    })) // Map to objects with `id` and `value` properties
  console.log('getSelectedEthnicIdentityEnumOptions', result)

  return result
}

function getSelectedEthnicIdentityStringptions(enumString: string): string[] {
  debugger
  if (isNullOrEmpty(enumString)) return []
  const keys = enumString.split(',').map((key) => key.trim()) // Split and trim keys
  return keys
}

export {
  EthnicIdentityEnum,
  getSelectedEthnicIdentityStringptions,
  printEthnicIdentity,
  getSelectedEthnicIdentityEnumOptions,
}
