import { Option } from '../../interfaces/Option';
import { addToCollection, isEmpty, removeFromCollection } from '../../../domain/utils';
import { Control, Controller } from '../../../infrastructure/deps/react-form';
import P from '../P/P';

interface CheckBoxProps {
  errors?: any;
  label: string;
  name: string;
  options: Option[];
  className: string;
  control: Control<any, any>;
  defaultValue?: string[]; // Default selected values
}

const CheckBoxGroup = ({ label, name, errors, className, control, options, defaultValue = [] }: CheckBoxProps) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue} // Set default values for the checkbox group
      render={({ field: { onChange, value = [] } }) => (
        <>
          <div>
            <P>{label}</P>
          </div>
          <div className={className}>
            {options.map((option) => (
              <div className="col-md-4" key={option.value}>
                <div className={`form-check form-check-custom form-check-solid mb-3`}>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    style={{ display: 'block' }}
                    onChange={(event) => {
                      if (event.target.checked) {
                        const updatedValue = addToCollection(value, option.value);
                        onChange(updatedValue);
                      } else {
                        const updatedValue = removeFromCollection(value, option.value);
                        onChange(updatedValue);
                      }
                    }}
                    value={option.value}
                    checked={value.includes(option.value)} // Check if the option is selected
                  />
                  <label className="form-check-label">{option.label}</label>
                </div>
              </div>
            ))}
            {!isEmpty(errors) && <span className="error text-danger">{errors}</span>}
          </div>
        </>
      )}
    />
  );
};

export default CheckBoxGroup;
